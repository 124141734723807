/* eslint-disable react-hooks/exhaustive-deps */
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogCloseButton,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Divider,
	VStack,
	HStack,
	Icon,
	Spacer,
	Text,
	Table,
	Tbody,
	Thead,
	Tr,
	Td,
	Th,
	TableContainer,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import ApplyLeaveForm from '../containers/leave/addLeave'
import LeaveList from '../containers/leave/leaveList'
import {
	useLeaveApplyData,
	useLeaveDeleteData,
	useLeaveEditData,
	useLeaveListData,
} from '../hooks/leave.hooks'
import moment from 'moment'
import { useLoadLeaveMapping } from '../hooks/asset.hooks'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_LEAVE,
//   sectionDetailedTrack,
// } from '../containers/repository/repo.utils'
import { toReturnLeavesCount } from '../utils/common.util'
import { useLanguageContext } from '../context/locale.context'
import { useAuthContext } from '../context/auth.context'

const headerStyle = {
	color: 'white !important',
	position: '-webkit-sticky',
	top: '0px',
	zIndex: '9',
	borderRight: '1px solid white',
}

export default function LeavePage() {
	const { mutate: applyLeaveMutate } = useLeaveApplyData()
	const { mutate: editLeaveMutate } = useLeaveEditData()
	const { mutate: deleteLeaveMutate } = useLeaveDeleteData()
	const { mutate: getLeaveListMutate, isLoading } = useLeaveListData()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { authData },
	} = useAuthContext()
	const { mutate: loadLeaveMappings } = useLoadLeaveMapping()
	const [isOpen, setIsOpen] = useState(false)
	const [leaveTypeOptions, setleaveTypeOptions] = useState([])
	const [leaveList, setLeaveList] = useState([])
	const [selectedLeave, setSelectedLeave] = useState()
	const [showLeaveForm, setShowLeaveForm] = useState(false)
	useEffect(() => {
		getLeaveListData()
		loadLeavData()
	}, [])
	const getLeaveListData = () => {
		getLeaveListMutate('', {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					setLeaveList(response)
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}

	const loadLeavData = () => {
		loadLeaveMappings(
			{
				leave_type_id: 0,
				start_from: 0,
				limit_value: 20,
			},
			{
				onSuccess: async res => {
					setleaveTypeOptions(res)
				},
				onError: err => {
					console.log({ err })
				},
			}
		)
	}
	const applyLeave = data => {
		let params = {
			leave_start_datetime: moment(data.startDate).format('YYYY-MM-DDTHH:mm'),
			leave_end_datetime: moment(data.endDate).format('YYYY-MM-DDTHH:mm'),
		}
		applyLeaveMutate(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					getLeaveListData()
					setShowLeaveForm(false)
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const onDeleteLeave = data => {
		setSelectedLeave(data)
		setIsOpen(true)
	}
	const handleEdit = data => {
		let params = {
			leave_start_datetime: data.startDate,
			leave_end_datetime: data.endDate,
			leave_workflow_id: data.leave_workflow_id,
		}
		editLeaveMutate(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					getLeaveListData()
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const coinfirmDelete = () => {
		setIsOpen(false)
		let params = {
			leave_workflow_id: selectedLeave.leave_workflow_id,
		}
		deleteLeaveMutate(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					getLeaveListData()
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	return (
		<>
			<VStack
				spacing='4'
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				w={'100%'}
			>
				{!!authData?.organization_flag_enable_leave &&
				leaveTypeOptions &&
				leaveTypeOptions.length > 0 ? (
					<TableContainer overflowY='scroll' overflowX='visible'>
						<Table
							size='sm'
							style={{
								// position: 'relative',
								borderCollapse: 'separate',
								borderSpacing: '0px 10px',
							}}
						>
							<Thead bg='#4daf89'>
								<Tr my={3} boxShadow='sm'>
									<Th textAlign='center' sx={headerStyle}>
										{locale['Leave Type']}
									</Th>
									<Th textAlign='center' sx={headerStyle}>
										{locale['Allocated']}
									</Th>
									<Th textAlign='center' sx={headerStyle}>
										{locale['Utilised']}
									</Th>
									<Th textAlign='center' sx={headerStyle}>
										{locale['Available']}
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{leaveTypeOptions.map((val, i) => (
									<Tr
										key={i}
										bg='white'
										my={1}
										p={2}
										borderRadius='md'
										boxShadow='sm'
									>
										<Td>{val.leave_type_name}</Td>
										<Td isNumeric>{val.leaves_allocated}</Td>
										<Td isNumeric>{val.leaves_used}</Td>
										<Td isNumeric>{val.leaves_unused}</Td>
									</Tr>
								))}
								<Tr>
									<Td p='2' pl='20px' isNumeric>
										{locale['Total']}
									</Td>
									<Td p='2' pl='20px' isNumeric>
										{toReturnLeavesCount(leaveTypeOptions, 'leaves_allocated')}
									</Td>
									<Td p='2' pl='20px' isNumeric>
										{toReturnLeavesCount(leaveTypeOptions, 'leaves_used')}
									</Td>
									<Td p='2' pl='20px' isNumeric>
										{toReturnLeavesCount(leaveTypeOptions, 'leaves_unused')}
									</Td>
								</Tr>
							</Tbody>
						</Table>
					</TableContainer>
				) : authData?.organization_id !== 868 ? (
					<Text alignContent='center'>
						Please contact Admin to enable Leave Settings !
					</Text>
				) : null}

				{authData?.organization_id === 868 ? (
					!!showLeaveForm ? (
						<ApplyLeaveForm
							leaveList={leaveList}
							onApplyLeave={applyLeave}
							onClose={() => setShowLeaveForm(false)}
						/>
					) : (
						// <HStack w="100%">
						//   <Spacer />
						<Button
							justifySelf='flex-end'
							leftIcon={<Icon as={BsPlus} w={8} h={8} />}
							variant='solid'
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							borderRadius='sm'
							fontWeight='400'
							boxShadow='md'
							onClick={() => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_LEAVE,
								//   action: 'Add Leave',
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Unavailability',
								//   buttonName: 'Add Leave',
								// })
								setShowLeaveForm(true)
							}}
						>
							{locale['Add leave']}
						</Button>
						// {/* </HStack> */}
					)
				) : null}
			</VStack>

			{authData?.organization_id === 868 ? (
				<VStack spacing='4' alignItems='flex-start' w={'100%'} h={'55vh'}>
					<LeaveList
						leaveList={leaveList}
						isLoading={isLoading}
						onDeleteLeave={onDeleteLeave}
						onEdit={handleEdit}
					/>
				</VStack>
			) : null}
			<AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<AlertDialogOverlay>
					<AlertDialogContent borderRadius='sm'>
						<AlertDialogHeader fontSize='lg' fontWeight='400'>
							{locale['Delete Leave']}
						</AlertDialogHeader>
						<Divider />
						<AlertDialogCloseButton />
						<AlertDialogBody>
							{locale["Are you sure? You can't undo this action afterwards."]}
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button
								borderRadius='sm'
								size='sm'
								variant='outline'
								colorScheme={localStorage.getItem('color')}
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_LEAVE,
									//   action: 'Cancel',
									// })
									setIsOpen(false)
								}}
							>
								{locale['Cancel']}
							</Button>
							<Button
								borderRadius='sm'
								size='sm'
								variant='solid'
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_LEAVE,
									//   action: 'Delete',
									// })
									coinfirmDelete()
								}}
								ml={3}
							>
								{locale['Delete']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { HStack, Text, Box, FormControl, Select } from '@chakra-ui/react'

import { SortOptionList } from '../../src/containers/action-panel/activity-listing-view/sort.option'
import CustomMenu from '../components/menu'
import {
  useLoadActivityListV1,
  useLoadChildOrderActivity,
} from '../hooks/activity.hooks'
import { useSharedContext } from '../context/shared.context'
import WorkspacePanel from '../containers/workspace-panel/workspace-panel'
import GanttView from '../containers/ganttChart/GanttView'
import GanttAutoSelect from '../containers/ganttChart/autoSelect'
import { useLanguageContext } from '../context/locale.context'

export const Gantt_PANEL_VIEW = {
  GANTT_VIEW: 'GANTT_VIEW',
  TIMELINE_VIEW: 'TIMELINE_VIEW',
}

const GanttChartPage = () => {
  const [selectedSort, setselectedSort] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [data, setdata] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [activity, setactivity] = useState({})

  const [activitySelec, setactivitySelec] = useState('')
  const [pageView, setPageView] = useState(null)
  const { mutate: activityListV1Mutate } = useLoadActivityListV1()
  const { mutate: activityChildOrderListMutate } = useLoadChildOrderActivity()
  const { dispatch } = useSharedContext()
  const {
    state: { locale },
  } = useLanguageContext()

  useEffect(() => {
    setselectedSort(SortOptionList(locale)[2])
  }, [])

  const onSelectActivity = activity => {
    setPageView(Gantt_PANEL_VIEW.GANTT_VIEW)
    setactivity(activity)
  }

  const handleActivitySelection = event => {
    let eventVal = event
    setactivitySelec(eventVal)
    let findActivity = data?.list.find(val => val.activity_id === eventVal)
    if (!!findActivity) onSelectActivity(findActivity)
  }

  const onSuccess = (res, isChild = false, pagestart = 0) => {
    if (pagestart === 0) {
      setdata(res)
    } else if (!!data && !!res) {
      const { list, ...rest } = res
      setdata({
        ...rest,
        list: [...data.list, ...list],
      })
    }
  }

  const loadActivity = ({
    start = 0,
    searchQuery = '',
    // isRefresh = !!selectedQueue.isRefresh ? selectedQueue.isRefresh : false,
    selectedSort = SortOptionList(locale)[0],
    selectedStatus,
  }) => {
    setIsBtnLoading(true)
    setIsRefresh(false)
    if (!start) {
      setIsLoading(true)
    }
    activityListV1Mutate(
      {
        sort_flag: 1,
        flag: 10,
        queue_id: 0,
        is_active: 0,
        is_due: 0,
        current_datetime: '',
        is_unread: 0,
        is_search: searchQuery ? 1 : 0,
        search_string: searchQuery,
        status_type_id: 0,
        activity_status_id: 0,
        activity_type_id: 0,
        activity_type_category_id: 11,
        page_start: start,
        page_limit: 30,
        start_from: 0,
        limit_value: 30,
        tag_id: 0,
        tag_type_id: 0,
      },
      {
        onSuccess: async res => {
          if (res) {
            onSuccess(res, false, start)
          }
        },
        onError: async err => {
          console.log('error occurred!', err)
        },
      }
    )
  }

  useEffect(() => {
    setSearchQuery('')
    loadActivity({
      searchQuery,
      selectedSort,
      // selectedStatus,
    })
  }, [selectedSort])

  useEffect(() => {
    if (!data && !isLoading) {
      loadActivity({
        searchQuery,
        selectedSort,
        // selectedStatus,
      })
    }
  }, [data])

  return (
    <HStack alignItems="flex-start" className={`files-page`}>
      <Box
        className={`files-page-box`}
        flex={1}
        minH="calc(100vh - 0px)"
        maxH="calc(100vh - 0px)"
        h="calc(100vh - 0px)"
        w="calc(100vw - 500px)"
      >
        <Box width="200px">
          <GanttAutoSelect handleActivitySelection={handleActivitySelection} />
          {/* <FormControl>
            <FormLabel>Select type</FormLabel>
            <Select
              placeholder="Select Activity"
              value={activitySelec}
              onChange={handleActivitySelection}
            >
              {data?.list &&
                data.list.map(val => (
                  <option value={val.activity_id}>{val.activity_title}</option>
                ))}
            </Select>
          </FormControl> */}
        </Box>
        {pageView === Gantt_PANEL_VIEW.GANTT_VIEW ? (
          <GanttView setPageView={setPageView} activity={activity} />
        ) : pageView === Gantt_PANEL_VIEW.TIMELINE_VIEW ? (
          <WorkspacePanel
            isDashboard={true}
            onWidgetViewChange={() => {
              setPageView(Gantt_PANEL_VIEW.GANTT_VIEW)
            }}
          />
        ) : (
          <Box position="absolute" top="50%" left="40%">
            <Text fontSize="2xl">{locale['Please select any activity']}</Text>
          </Box>
        )}
      </Box>
    </HStack>
  )
}

export default GanttChartPage

/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useDisclosure } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import FieldEditItem from '../../containers/form-edit-panel/edit-view/fieldItemEdit'
import { useSmartFormContext } from '../../context/smart-form.context'
import { useSharedContext } from '../../context/shared.context'

import { formatDDMMYYYYHHMM, getCurrentDateUTC } from '../../utils/date.utils'
import { getMessageUniqueId } from '../../utils/common.util'
import calcStub from '../../utils/vil_form_config.util'
import fieldEditConfig from '../../utils/field_edit_config'

const annexureFieldArray = [
	'11182i',
	'11746i',
	'12415i',
	'12949i',
	'12963i',
	'12993i',
	'12891i',
	'10489i',
	'11748i',
	'11029i',
	'26440i',
	'219367i',
	'347187i',
]

const FormEditView = ({
	formData,
	fieldList,
	activity,
	setFieldList,
	isFormEnable,
	onShowHistory = () => {},
	onShowDescription = () => {},
	isSmartForm,
	disable_actions = false,
}) => {
	const {
		state: { fields: formFields },
	} = useSmartFormContext()
	const fields = !!formFields[formData.form_id]
		? formFields[formData.form_id]
		: {}

	console.log({ fields })

	const { dispatch } = useSharedContext()
	const [currentField, setcurrentField] = useState({})
	const [isCurrentFieldValid, setisCurrentFieldValid] = useState(false)
	const [activityType, setActivityType] = useState(null)
	const [activityStatus, setActivityStatus] = useState(null)
	const [error, setError] = useState(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const { form_transaction_id, form_id, disableEdit, activity_type_id } =
		formData
	const { activity_id, activity_title } = activity

	const onEditField = field => {
		setcurrentField(field)
	}

	const onFieldSave = async field_id => {}

	return (
		<Box boxShadow={'none'} w='full' p={2} height='100%' marginBottom={20}>
			{(fieldList || []).map(field => {
				const { form_id, form_workflow_activity_type_id } = formData
				const is_annexure = annexureFieldArray.includes(field.field_id + 'i')
				const is_edit_config =
					!!fieldEditConfig[form_workflow_activity_type_id] &&
					!!fieldEditConfig[form_workflow_activity_type_id][form_id] &&
					!!fieldEditConfig[form_workflow_activity_type_id][form_id][
						field.field_id
					]
				return (
					<FieldEditItem
						key={field.field_id}
						field={field}
						isFormEnable={isFormEnable}
						onEdit={onEditField}
						currentField={currentField}
						disableEdit={disableEdit}
						onEditCancel={() => {
							setcurrentField({})
							setisCurrentFieldValid(false)
							setFieldList()
						}}
						onFieldSave={onFieldSave}
						isCurrentFieldValid={isCurrentFieldValid}
						validateValue={e => setisCurrentFieldValid(e)}
						activity={activity}
						onShowHistory={onShowHistory}
						onShowDescription={onShowDescription}
						is_annexure={is_annexure}
						is_edit_config={is_edit_config}
						disable_actions={disable_actions}
						isSmartForm={isSmartForm}
						fieldList={fieldList}
					/>
				)
			})}
		</Box>
	)
}

export default FormEditView

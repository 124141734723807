import { Box, HStack, Center, Switch, useColorMode } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Card from '../components/card'
import ActionPanel from '../containers/action-panel/action-panel'
import OriginFormPanel from '../containers/form-panel/form-panel'
import WorkspacePanel from '../containers/workspace-panel/workspace-panel'
import { sharedConst } from '../utils/action.constant'
import { useAuthContext } from '../context/auth.context'
import { useSharedContext } from '../context/shared.context'
import { useLoadActivityDetails } from '../hooks/activity.hooks'
import { GridLoader } from 'react-spinners'

const VIEW = {
	WORKSPACE_VIEW: 'WORKSPACE_VIEW',
	FORM_VIEW: 'FORM_VIEW',
	LOADER_VIEW: 'LOADER_VIEW',
}

export default function FilesPage(props) {
	const { toggleColorMode } = useColorMode()
	const [panelView, setpanelView] = useState(VIEW.WORKSPACE_VIEW)
	const { mutate: mutateLoadActivity } = useLoadActivityDetails()
	const [toggleButton, setToggleButton] = useState(false)
	const {
		state: { activity },
		dispatch,
	} = useSharedContext()
	const {
		state: { authData },
	} = useAuthContext()

	const doRefresh = (i = 0, event) => {
		let count = event?.activity_type_category_id === 53 ? 5 : 3
		let timer = setTimeout(() => {
			console.log('refresh ===> ', i, new Date().toLocaleString())
			dispatch({
				type: sharedConst.SHOW_LOADER,
			})
			dispatch({
				type: sharedConst.REFRESH_TIMELINE,
			})
			doRefresh(i + 1, event)
		}, 3000)
		if (i > count) {
			clearTimeout(timer)
			//setpanelView(VIEW.WORKSPACE_VIEW);
			dispatch({
				type: sharedConst.REFRESH_LISTING,
			})
			dispatch({
				type: sharedConst.REFRESH_TIMELINE,
			})
			dispatch({
				type: sharedConst.HIDE_LOADER,
			})
		}
	}

	return (
		<HStack alignItems='flex-start' className={`files-page`}>
			{toggleButton && authData?.organization_flag_enable_collapsible === 1 ? (
				<Card
					className={`files-page-card`}
					minH='calc(100vh - 92px)'
					maxH='calc(100vh - 92px)'
					h='calc(100vh - 92px)'
					overflowY='auto'
					w={{ base: '250', md: '350px', lg: '350px' }}
				>
					<ActionPanel
						openFormView={() => setpanelView(VIEW.FORM_VIEW)}
						openActivityView={() => setpanelView(VIEW.WORKSPACE_VIEW)}
						toggleButton={toggleButton}
						setToggleButton={setToggleButton}
					/>
				</Card>
			) : !toggleButton &&
			  authData?.organization_flag_enable_collapsible !== 1 ? (
				<Card
					className={`files-page-card`}
					minH='calc(100vh - 92px)'
					maxH='calc(100vh - 92px)'
					overflowY='auto'
					h='calc(100vh - 92px)'
					w={{ base: '250', md: '350px', lg: '350px' }}
				>
					<ActionPanel
						openFormView={() => setpanelView(VIEW.FORM_VIEW)}
						openActivityView={() => setpanelView(VIEW.WORKSPACE_VIEW)}
						toggleButton={toggleButton}
						setToggleButton={setToggleButton}
					/>
				</Card>
			) : (
				<Switch
					isChecked={toggleButton}
					id='toggle'
					onChange={() => {
						setToggleButton(true)
					}}
				/>
			)}
			<Box
				className={`files-page-box`}
				flex={1}
				minH='calc(100vh - 92px)'
				maxH='calc(100vh - 92px)'
				h='calc(100vh - 92px)'
				w='calc(100vw - 500px)'
			>
				{panelView === VIEW.FORM_VIEW ? (
					<OriginFormPanel
						onClose={event => {
							setpanelView(VIEW.WORKSPACE_VIEW)
							doRefresh(0, event)
						}}
					/>
				) : panelView === VIEW.LOADER_VIEW ? (
					<Box h='calc(100vh - 180px)' w='full'>
						<Center p='1' h={'100%'}>
							<GridLoader size={30} margin={5} color='#64bc78' />
						</Center>
					</Box>
				) : (
					<WorkspacePanel setToggleButton={setToggleButton} />
				)}
			</Box>
		</HStack>
	)
}

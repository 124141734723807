/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
	LOGIN_MODE,
	LS_LOGIN_MODE,
	LS_LOGIN_MODE_NAME,
} from '../components/login/login.util'
import { OTPLogin } from '../components/login/otp-login'
import { QrCodeLogin } from '../components/login/qr-code-login'
import { EmailOTPLogin } from '../components/login/email-login'
import PublicLayout from '../layouts/public.layouts'
import { useParams, useLocation } from 'react-router-dom'
import AWS from 'aws-sdk'
import {
	setLocalAuthDetails,
	decryptData,
	AuthMode,
} from '../utils/common.util'
import {
	AUTH_LOGIN_MODE,
	AUTH_PAYLOAD,
	AUTH_SESSION_CHECK,
	AUTH_SESSION_DATA,
} from '../utils/constant'
import { useAuthContext } from '../context/auth.context'
import { loginConst } from '../utils/action.constant'
import { useCognitoSignIn } from '../hooks/login.hooks'
import { useHistory } from 'react-router'
import { AzureADLogin } from '../components/login/azure-login'
import { Text } from '@chakra-ui/react'

export default function LoginPage(props) {
	const [loginMode, setloginMode] = useState(LOGIN_MODE.QR)
	const urlParams = useParams()
	const [loginError, setLoginError] = useState(false)
	const { mutate } = useCognitoSignIn()
	const { dispatch } = useAuthContext()
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		console.log(urlParams)
		if (!!urlParams.params) {
			const authData = decryptData(urlParams.params)
			setLogin(authData)
		}
		console.log({ dd: localStorage.getItem(loginConst.IS_AZURE_LOGIN) })
		if (localStorage.getItem(loginConst.IS_AZURE_LOGIN)) {
			sessionStorage.setItem(
				AUTH_LOGIN_MODE,
				LS_LOGIN_MODE[LOGIN_MODE.AZURE_AD]
			)
			setloginMode(LOGIN_MODE.AZURE_AD)
		}
	}, [])

	useEffect(() => {
		if (loginMode in LS_LOGIN_MODE)
			sessionStorage.setItem(AUTH_LOGIN_MODE, LS_LOGIN_MODE[loginMode])
	}, [loginMode])

	const onSetAwsConfig = ({ idToken }) => {
		// Set the region where your identity pool exists (us-east-1, eu-west-1)
		AWS.config.region = process.env.REACT_APP_AWS_REGION

		// Configure the credentials provider to use your identity pool
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
			Logins: {
				[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]:
					idToken.jwtToken,
			},
		})
	}

	const setLogin = authData => {
		localStorage.setItem(AUTH_PAYLOAD, authData)
		const auth = JSON.parse(atob(authData))
		mutate(auth, {
			onSuccess: data => {
				setLocalAuthDetails({
					account_id: auth.accountId,
					asset_id: auth.assetId,
					asset_token_auth: auth.encToken,
					asset_encryption_token_id: auth.encToken,
					organization_id: auth.organizationId,
					workforce_id: auth.workforceId,
				})
				dispatch({
					type: loginConst.LOGIN,
					payload: {
						...auth,
						...data,
					},
				})
				sessionStorage.setItem(AUTH_SESSION_CHECK, true)
				sessionStorage.setItem(AUTH_SESSION_DATA, JSON.stringify(data))
				onSetAwsConfig(data)
				history.push('/files')
			},
			onError: error => {
				console.log('error', error)
				setLoginError(true)
				dispatch({
					type: loginConst.LOGIN_IN_FAILED,
					payload: {
						error,
					},
				})
			},
		})
	}

	if (loginMode === LOGIN_MODE.OTP) {
		return (
			<PublicLayout>
				<OTPLogin setLoginMode={setloginMode} />
				<Text mt={4}>If you are experiencing login issues,</Text>
				<Text>
					please contact us at <b>support@grenerobotics.com</b> for assistance.
				</Text>
			</PublicLayout>
		)
	}
	if (loginMode === LOGIN_MODE.EMAIL) {
		return (
			<PublicLayout>
				<EmailOTPLogin setLoginMode={setloginMode} />
				<Text mt={4}>If you are experiencing login issues,</Text>
				<Text>
					please contact us at <b>support@grenerobotics.com</b> for assistance.
				</Text>
			</PublicLayout>
		)
	}
	if (loginMode === LOGIN_MODE.AZURE_AD) {
		return (
			<PublicLayout>
				<AzureADLogin setLoginMode={setloginMode} />
				<Text mt={4}>If you are experiencing login issues,</Text>
				<Text>
					please contact us at <b>support@grenerobotics.com</b> for assistance.
				</Text>
			</PublicLayout>
		)
	}
	return (
		<PublicLayout>
			<QrCodeLogin setLoginMode={setloginMode} />
		</PublicLayout>
	)
}

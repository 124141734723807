/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Spacer,
  Text,
  VStack,
  Box,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import { BiDownload } from 'react-icons/bi'
import {
  useLoadFormFieldListData,
  useLoadFormAttachments,
  useLoadFieldHistory,
  useLoadWorkFlowList,
} from '../../hooks/form.hooks'
import {
  useSubmitForm,
  useLoadActivityStatusList,
  useLoadActivityTypeList,
  useLoadActivityParticipant,
} from '../../hooks/activity.hooks'
import { useAuthContext } from '../../context/auth.context'
import { useSharedContext } from '../../context/shared.context'
import { useSmartFormContext } from '../../context/smart-form.context'
import { sharedConst, smartFormConst } from '../../utils/action.constant'
import {
  prepareFormSaveParams,
  prepareFinalFieldValueForEdit,
} from '../../utils/form.util'
import { downloadAwsFile } from '../../utils/aws.util'
import {
  toSnakeCase,
  formatDDMMYYYYHHMM,
  formatLocalDDMMYYYYHHMM,
  formatDDMMMYYYY,
  getLocalTimeDate,
} from '../../utils/common.util'
import '../../containers/form-edit-panel/style.scss'
import FormEditView from './export-view'
import { sectionDetailedTrack } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const FormEditPanel = ({
  isOpen,
  formData,
  activity,
  onClose,
  isOrigin = false,
  refillForm,
  isFormEnable,
  height,
  hideedit = false,
}) => {
  const [fieldList, setfieldList] = useState([])
  const [refillVal, setRefillVal] = useState(null)
  const [isSmartForm, setIsSmartForm] = useState(null)
  const [isEditable, setIsEditable] = useState(null)
  const [openModal, setModal] = useState(false)
  const [activityType, setActivityType] = useState(null)
  const [activityStatus, setActivityStatus] = useState(null)
  const [hFieldName, setHFieldName] = useState('')
  const [historyData, setHistoryData] = useState([])

  const { mutate, isLoading } = useLoadFormFieldListData()

  const {
    isOpen: isHistoryOpen,
    onOpen: onHistoryOpen,
    onClose: onHistoryClose,
  } = useDisclosure()
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const {
    state: { fields },
    dispatch,
  } = useSmartFormContext()

  const { dispatch: sharedDispatch } = useSharedContext()

  const toast = useToast()
  const [docsFields, setdocsFields] = useState([])
  const [participantList, setparticipants] = useState([])
  const {
    activity_id,
    activity_type_id,
    parent_status_id,
    activity_status_id,
    activity_type_category_id,
  } = activity

  useEffect(() => {
    fetchFieldListData()
  }, [])

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          ...formData,
          organization_id: formData.organization_id || authData.organization_id,
          account_id: formData.account_id || authData.account_id,
          workforce_id: formData.workforce_id || authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          ...formData,
          activity_id: 0,
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
        is_from_timeline: formData.is_from_timeline,
        submitted_data: formData.submitted_data,
      },
      {
        onSuccess: async data => {
          const isSmart = !!data.map(i => i.next_field_id).filter(Boolean)
            .length
          const isEditable = !!data
            .map(i => i.field_value_edit_enabled)
            .filter(Boolean).length
          setIsSmartForm(!!isSmart)
          setIsEditable(isEditable)
          let finalFields = data
          if (!!isSmart) {
            finalFields = data.filter(f => !!f.data)
          }
          setfieldList(finalFields)
          let fields = {}
          fields[formData.form_id] = {}
          finalFields.forEach(item => {
            if (!!item.data) {
              fields[formData.form_id][`${item.field_id}`] = item.data
            }
          })
          dispatch({
            type: smartFormConst.SET_FIELD_VALUES,
            formId: formData.form_id,
            fields,
          })
        },
        onError: async err => {
          console.log('error occurred while loading fields', err)
        },
      }
    )
  }

  const onShowHistory = field => {}

  const { disabledProperty, isFormDisabled, operating_asset_id } = formData
  const { activity_master_data } = activity
  let form_fill_request
  let delegateParticipant = []
  let formId = !!formData ? formData.form_id : ''
  if (!!activity_master_data) {
    form_fill_request = JSON.parse(activity_master_data).form_fill_request
    if (!!form_fill_request) {
      delegateParticipant = form_fill_request.filter(item => {
        return item.form_id === formId
      })
    }
  }
  let isOwner
  if (!!authData && authData.asset_flag_super_admin) {
    isOwner = !!authData.asset_flag_super_admin
  } else {
    isOwner =
      participantList &&
      participantList.find(participant => {
        return (
          participant.asset_id === authData.asset_id &&
          participant.asset_flag_is_owner
        )
      })
  }
  const isMyForm = operating_asset_id === authData.operating_asset_id
  let isRollBackAccess = delegateParticipant.findIndex(
    user => user.asset_id === authData.asset_id
  )
  const disable_actions =
    isSmartForm !== null &&
    (isRollBackAccess !== -1 || isOwner || isMyForm) &&
    !formData.isCompleted &&
    isEditable &&
    !hideedit

  return (
		<VStack
			className={`smart-form-panel-confirm-container`}
			w='full'
			justifyContent='flex-start'
			px={4}
			height={'full'}
		>
			{!!formData ? (
				<HStack my={4} w='full' p={2} mx={4} bg='secondary' maxH='70px'>
					<Text fontSize='md' fontWeight='600' textAlign='center'>
						{formData.form_name || ''}
					</Text>
				</HStack>
			) : null}
			{isLoading && fieldList.length === 0
				? [1, 2, 3, 4].map(i => {
						return <Skeleton w='full' h='70px' key={i} />
				  })
				: null}
			<Box w='full' h='full' p={2}>
				<FormEditView
					isFormEnable={isFormEnable}
					fieldList={fieldList}
					formData={formData}
					activity={activity}
					setFieldList={() => fetchFieldListData()}
					onShowHistory={onShowHistory}
					isSmartForm={isSmartForm}
					disable_actions={disable_actions}
				/>
			</Box>
		</VStack>
	)
}

export default FormEditPanel

import React from 'react';
import { HStack } from '@chakra-ui/react';
import ExportWorkspace from '../containers/export-panel';

export default function ExportPage(props) {
  return (
    <HStack alignItems="flex-start">
      <ExportWorkspace />
    </HStack>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Flex,
	useToast,
	Box,
	Button,
	Divider,
	HStack,
	Icon,
	IconButton,
	Slider,
	SliderFilledTrack,
	SliderTrack,
	Tag,
	TagLabel,
	Text,
	VStack,
	Center,
	Stack,
} from '@chakra-ui/react'
import { Collapse } from '@chakra-ui/transition'
import { format } from 'date-fns'
import Card from '../../components/card'
import React, { useEffect, useState, useCallback, forwardRef } from 'react'
import { useParams } from 'react-router-dom'
import { useLanguageContext } from '../../context/locale.context'
import {
	getLocalAuthDetails,
	toSnakeCase,
	rag_status,
} from '../../utils/common.util'
import { FiCopy } from 'react-icons/fi'
import { MdDateRange, MdTimelapse, MdAccessTime } from 'react-icons/md'
import { useAuthContext } from '../../context/auth.context'
import { QRCodeSVG } from 'qrcode.react'

import {
	useLoadActivityDetails,
	useLoadTimelineList,
} from '../../hooks/activity.hooks'
import { useSharedContext } from '../../context/shared.context'
import TimelineComponents from '../../components/timeline/components'
import { TIMELINE_MESSAGE_TYPE } from '../../components/timeline/util'
import { SmartFormContextProvider } from '../../context/smart-form.context'
import FormEditPanel from './export-form-edit-panel'

const ExportData = forwardRef(
	(
		{
			filter = {
				value: TIMELINE_MESSAGE_TYPE.ALL,
				label: 'All',
			},
			exportParams = {},
		},
		ref
	) => {
		const params = useParams()
		const [exportPayload, seExportPayload] = useState(null)
		const [hideTimelineView, setHideTimelineView] = useState(true)
		const [isSysMessageEnable, setIsSysMessageEnable] = useState(false)
		const [exportTimelineView, setExportTimelineView] = useState(false)
		const [userData, setUserData] = useState({})
		const [queueData, setQueueData] = useState({})
		const [activity, setActivity] = useState({})
		const localData = getLocalAuthDetails()
		const [data, setdata] = useState({})

		const {
			state: { authData },
		} = useAuthContext()
		const {
			state: { locale },
		} = useLanguageContext()
		const {
			state: { timelineFilter },
		} = useSharedContext()

		const { mutate: mutateLoadActivityDetail } = useLoadActivityDetails()
		const { mutate } = useLoadTimelineList()

		const { asset_id, asset_flag_super_admin, organization_ai_bot_enabled } =
			authData
		useEffect(() => {
			// const exportPayload = !!params?.params
			// 	? JSON.parse(params.params)
			// 	: exportParams
			const exportPayload = exportParams
			console.log({ exportPayload, locale, authData, localData })
			if (!!exportPayload?.activity_id) {
				setQueueData({
					activity_id: exportPayload.activity_id,
					activity_type_id: exportPayload.activity_type_id,
					activity_type_category_id: exportPayload.activity_type_category_id,
					asset_unread_updates_count: 0,
				})
				setUserData({
					organization_id: exportPayload.organization_id,
					account_id: exportPayload.account_id,
					workforce_id: exportPayload.workforce_id,
					asset_id: exportPayload.asset_id,
					asset_token_auth: exportPayload.asset_token_auth,
					authToken: exportPayload.asset_token_auth,
					activity_id: exportPayload.activity_id,
					auth_asset_id: exportPayload.asset_id,
					organization_flag_enable_grid: 0,
					asset_image_path: '',
				})
				setIsSysMessageEnable(exportPayload.isSysMessageEnable)
				seExportPayload(exportPayload)
			}
		}, [])
		const loadDetails = useCallback(activity => {
			mutateLoadActivityDetail(
				{
					...userData,
					activity_id: activity.activity_id,
					activity_type_id: activity.activity_type_id,
				},
				{
					onSuccess: async details => {
						setActivity(details)
					},
					onError: async err => {
						console.log('Err occurred', err)
					},
				}
			)
		})
		useEffect(() => {
			if (!!queueData?.activity_id) {
				loadDetails(queueData)
			}
		}, [queueData])
		useEffect(() => {
			if (!!exportPayload && exportPayload.isTimelineViewExport) {
				const payload = {
					organization_id: exportPayload.organization_id,
					account_id: exportPayload.account_id,
					workforce_id: exportPayload.workforce_id,
					asset_id: exportPayload.asset_id,
					auth_asset_id: exportPayload.asset_id,
					asset_type_id: 0,
					asset_token_auth: exportPayload.asset_token_auth,
					activity_id: exportPayload.activity_id,
					activity_type_id: exportPayload.activity_type_id,
					device_os_id: 5,
					activity_type_category_id: exportPayload.activity_type_category_id,
					asset_unread_updates_count: 0,
					organization_flag_enable_grid: 0,
					asset_image_path: '',
				}
				loadTimeline({ activity: payload })
			}
		}, [exportPayload])
		const onSuccess = res => {
			console.log({ res })
			const { list, ...rest } = res
			if (!data || !Object.keys(data).length) {
				setdata({
					...rest,
					list: list.reverse(),
				})
			}
		}
		const loadTimeline = useCallback(({ activity, start = 0 }) => {
			mutate(
				{
					...activity,
					file_unread_count: activity.asset_unread_updates_count,
					flag_previous: -2,
					page_start: start,
					page_limit: 500, //activity.asset_unread_updates_count load only unread details
					datetime_differential: 0,
					timeline_transaction_id: 0,
					sort_flag: 0,
					sort_order: 0,
				},
				{
					onSuccess: res => {
						onSuccess(res)
					},
				}
			)
		})
		const timeColor = datetime => {
			let isDelayed =
				new Date(datetime.split(' ').join('T')).setHours(0, 0, 0, 0) <
				new Date().setHours(0, 0, 0, 0)
			if (!!isDelayed) {
				return 'red'
			} else {
				return ''
			}
		}
		const {
			activity_lead_operating_asset_first_name,
			operating_asset_first_name,
			activity_type_category_name,
			activity_type_category_id,
			activity_type_name,
			activity_status_name,
			activity_cuid_1,
			activity_status_id,
			activity_cuid_2,
			activity_cuid_3,
			activity_datetime_start_expected,
			activity_datetime_end_deferred,
			activity_datetime_end_status,
			participant_list,
			activity_rag_status_name,
			activity_workflow_completion_percentage: completion,
			activity_type_id,
			activity_lead_operating_asset_id,
			activity_title,
		} = activity
		return (
			<div ref={ref} className={`workspace-panel-container`}>
				{!!exportPayload && exportPayload.isTimelineViewExport ? (
					<page>
						<Card className={`activity-container`}>
							<HStack
								alignItems='center'
								bg={
									!!activity?.activity_priority_enabled
										? localStorage.getItem('color')
										: localStorage.getItem('color')
								}
								padding={!!activity?.activity_priority_enabled ? '2' : '2'}
								className={`activity-${toSnakeCase(activity?.activity_title)}`}
							>
								<Text
									flex={1}
									fontSize='md'
									color='white'
									textTransform='capitalize'
									className={`activity-name-text-${toSnakeCase(
										activity_title
									)}`}
								>
									{activity_title}
								</Text>
							</HStack>
							<Collapse
								in={true}
								animateOpacity
								className={`activity-body-${toSnakeCase(activity_title)} ${
									true ? 'expand' : ''
								}`}
							>
								<Divider py={1} />
								<Box px={1} mt={2} shadow='md' bg={'white'}>
									<HStack alignItems='flex-start' justifyContent='flex-start'>
										<VStack flex={1} alignItems='flex-start'>
											<HStack
												alignItems='flex-start'
												justifyContent='flex-start'
												mb={1}
											>
												{activity_status_name && (
													<>
														<Tag
															className={`activity-status-tag-${toSnakeCase(
																activity_status_name
															)}`}
															variant='outline'
															borderRadius='full'
															colorScheme={localStorage.getItem('color')}
															size='md'
															fontSize='12px'
															mb={1}
														>
															<TagLabel maxW='full'>
																{activity_status_name}
															</TagLabel>
														</Tag>
													</>
												)}

												{activity_rag_status_name && (
													<>
														<Text>{locale['RAG Status']}: </Text>
														<Tag
															className={`activity-status-tag-${toSnakeCase(
																activity_rag_status_name
															)}`}
															variant='outline'
															borderRadius='full'
															colorScheme={`${
																rag_status[
																	activity_rag_status_name.toLowerCase()
																]
															}`}
															size='md'
															fontSize='12px'
															mb={1}
														>
															<TagLabel maxW='full'>
																{activity_rag_status_name}
															</TagLabel>
														</Tag>
													</>
												)}
											</HStack>
										</VStack>
									</HStack>
									<HStack spacing={1} alignItems='flex-start'>
										<VStack flex={1} alignItems='flex-start'>
											<HStack
												alignItems='flex-start'
												justifyContent='flex-start'
											>
												<Text
													fontSize='12px'
													mb={1}
													className={`activity-type-${toSnakeCase(
														activity_type_category_name
													)}`}
												>
													{activity_type_category_name} :{' '}
													<Text
														as='span'
														fontWeight='500'
														className={`activity-type-${toSnakeCase(
															activity_type_name
														)}`}
													>
														{activity_type_name}
													</Text>
												</Text>
												{!!completion && (
													<HStack
														spacing={2}
														flex={1}
														className={`activity-completion-container`}
													>
														<Slider
															className={`activity-completion-slider`}
															aria-label='slider-ex-2'
															isDisabled={true}
															// defaultValue={completion || 0}
															value={completion}
															w='150px'
														>
															<SliderTrack
																height={2}
																borderRadius='full'
																className={`activity-completion-track`}
															>
																<SliderFilledTrack
																	bg='orange.600'
																	className={`activity-completion-fill`}
																/>
															</SliderTrack>
														</Slider>
														<Text
															className={`activity-completion-text-percentage`}
															color={localStorage.getItem('color')}
														>
															{`${completion}%` || 0}
														</Text>
													</HStack>
												)}
											</HStack>
										</VStack>
										<VStack
											className={`activity-creation-details`}
											w='300px'
											justifyContent='flex-start'
											alignItems='flex-start'
										>
											<Text fontSize='12px' className={`activity-creator-text`}>
												{locale['Creator']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-creator-text-${toSnakeCase(
														operating_asset_first_name
													)}`}
												>
													{operating_asset_first_name}
												</Text>
											</Text>
										</VStack>
										<VStack
											alignItems='flex-start'
											className={`activity-time-log-container`}
										>
											<Text
												fontSize='12px'
												display='flex'
												alignItems='center'
												className={`activity-time-text-${activity_datetime_start_expected}`}
											>
												<Icon
													as={MdDateRange}
													w={4}
													h={4}
													className={`activity-date-time-icon`}
												/>
												&nbsp; {locale['Start Date']}: &nbsp;
												{/* <Text display='flex' alignItems='center' > */}
												{!!activity_datetime_start_expected
													? format(
															new Date(
																activity_datetime_start_expected
																	.split(' ')
																	.join('T')
															),
															'dd MMM yyyy'
													  )
													: !!activity_datetime_start_expected
													? format(
															new Date(
																activity_datetime_start_expected
																	.split(' ')
																	.join('T')
															),
															'dd MMM yyy'
													  )
													: null}
												{/* </Text> */}
											</Text>
											{!!activity_datetime_end_deferred && (
												<Text
													fontSize='12px'
													display='flex'
													alignItems='center'
													color={timeColor(activity_datetime_end_deferred)}
													className={`activity-end-time-text-${activity_datetime_end_deferred}`}
												>
													<Icon
														as={MdTimelapse}
														w={4}
														h={4}
														className={`activity-time-laps-icon`}
													/>
													&nbsp; {locale['Due Date']} : &nbsp;
													{/* <Text display='flex' alignItems='center'  color={timeColor(activity_datetime_end_deferred)}> */}
													{activity_datetime_end_deferred.includes('date') ||
													activity_datetime_end_deferred.includes('0000-00-00')
														? activity_datetime_end_deferred
														: format(
																new Date(
																	activity_datetime_end_deferred
																		.split(' ')
																		.join('T')
																),
																'dd MMM yyyy'
														  )}
													{/* </Text> */}
												</Text>
											)}
											{!!activity_datetime_end_status &&
												organization_ai_bot_enabled === 1 && (
													<Text
														fontSize='12px'
														display='flex'
														alignItems='center'
														color={timeColor(activity_datetime_end_status)}
														className={`activity-time-text-${activity_datetime_end_deferred}`}
													>
														<Icon
															as={MdAccessTime}
															w={4}
															h={4}
															className={`activity-date-time-icon`}
														/>
														&nbsp; {locale['Status Due Date']} : &nbsp;
														{/* <Text display='flex' alignItems='center' color={timeColor(activity_datetime_end_status)} > */}
														{!!activity_datetime_end_status
															? format(
																	new Date(
																		activity_datetime_end_status
																			.split(' ')
																			.join('T')
																	),
																	'dd MMM yyyy'
															  )
															: !!activity_datetime_end_status
															? format(
																	new Date(
																		activity_datetime_end_status
																			.split(' ')
																			.join('T')
																	),
																	'dd MMM yyy'
															  )
															: null}
														{/* </Text> */}
													</Text>
												)}
										</VStack>
										{activity_type_category_id === 66 && (
											<div style={{ padding: '3px 3px' }}>
												<QRCodeSVG
													renderAs='canvas'
													style={{
														height: '75px',
														width: '75px',
													}}
													level='Q'
													value={`${activity_cuid_1}`}
												/>
											</div>
										)}
									</HStack>
									<HStack
										className={`activity-cuid-container`}
										justifyContent='space-between'
										mt={2}
										alignItems='center'
										spacing={4}
									>
										{activity_cuid_1 && (
											<Text
												mb={1}
												fontSize='12px'
												className={`activity-CUID-1-text`}
											>
												{locale['CUID 1']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-CUID-1-text-${activity_cuid_1}`}
												>
													{activity_cuid_1}
												</Text>{' '}
												<Icon
													className={`activity-CUID-1-copy-icon`}
													as={FiCopy}
													cursor='pointer'
													w={3}
													h={3}
												/>{' '}
											</Text>
										)}
										{activity_cuid_2 && (
											<Text
												mb={1}
												fontSize='12px'
												className={`activity-CUID-2-text`}
											>
												{locale['CUID 2']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-CUID-2-text-${activity_cuid_2}`}
												>
													{activity_cuid_2}
												</Text>{' '}
												<Icon
													className={`activity-CUID-2-copy-icon`}
													as={FiCopy}
													cursor='pointer'
													w={3}
													h={3}
												/>{' '}
											</Text>
										)}
										{activity_cuid_3 && (
											<Text
												mb={1}
												className={`activity-CUID-3-text`}
												fontSize='12px'
											>
												{locale['CUID 3']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-CUID-3-text-${activity_cuid_3}`}
												>
													{activity_cuid_3}
												</Text>{' '}
											</Text>
										)}
									</HStack>
								</Box>
							</Collapse>
							<VStack
								className={`team-collaborations-timeline-container`}
								w='100%'
								style={{
									transformOrigin: 'bottom',
									transition: 'transform 0.26s ease',
								}}
								minH={'calc(100vh - 370px)'}
								flex={1}
							>
								<Box overflowY='scroll' h='100%' w='100%' px={1}>
									{data && data.list && data.list
										? data.list.map((timelineItem, index) => (
												<TimelineComponents
													filter={filter.value}
													onFormSelection={null}
													{...authData}
													key={timelineItem.timeline_transaction_id}
													timeline={timelineItem}
													timelineReplyData={e => {}}
													timelineScroll={null}
												/>
										  ))
										: null}
								</Box>
							</VStack>
						</Card>
					</page>
				) : (
					<page>
						<Card className={`activity-container`}>
							<HStack
								alignItems='center'
								bg={
									!!activity?.activity_priority_enabled
										? localStorage.getItem('color')
										: localStorage.getItem('color')
								}
								boxShadow='none'
								padding={!!activity?.activity_priority_enabled ? '2' : '2'}
								className={`activity-${toSnakeCase(activity?.activity_title)}`}
							>
								<Text
									flex={1}
									fontSize='md'
									color='white'
									textTransform='capitalize'
									className={`activity-name-text-${toSnakeCase(
										activity_title
									)}`}
								>
									{activity_title}
								</Text>
							</HStack>
							<Collapse
								in={true}
								animateOpacity
								className={`activity-body-${toSnakeCase(activity_title)} ${
									true ? 'expand' : ''
								}`}
							>
								<Divider py={1} />
								<Box px={1} mt={2} shadow='md' bg={'white'}>
									<HStack alignItems='flex-start' justifyContent='flex-start'>
										<VStack flex={1} alignItems='flex-start'>
											<HStack
												alignItems='flex-start'
												justifyContent='flex-start'
												mb={1}
											>
												{activity_status_name && (
													<>
														<Tag
															className={`activity-status-tag-${toSnakeCase(
																activity_status_name
															)}`}
															variant='outline'
															borderRadius='full'
															colorScheme={localStorage.getItem('color')}
															size='md'
															fontSize='12px'
															mb={1}
														>
															<TagLabel maxW='full'>
																{activity_status_name}
															</TagLabel>
														</Tag>
													</>
												)}
												{activity_rag_status_name && (
													<>
														<Text>{locale['RAG Status']}: </Text>
														<Tag
															className={`activity-status-tag-${toSnakeCase(
																activity_rag_status_name
															)}`}
															variant='outline'
															borderRadius='full'
															colorScheme={`${
																rag_status[
																	activity_rag_status_name.toLowerCase()
																]
															}`}
															size='md'
															fontSize='12px'
															mb={1}
														>
															<TagLabel maxW='full'>
																{activity_rag_status_name}
															</TagLabel>
														</Tag>
													</>
												)}
											</HStack>
										</VStack>
									</HStack>
									<HStack spacing={1} alignItems='flex-start'>
										<VStack flex={1} alignItems='flex-start'>
											<HStack
												alignItems='flex-start'
												justifyContent='flex-start'
											>
												<Text
													fontSize='12px'
													mb={1}
													className={`activity-type-${toSnakeCase(
														activity_type_category_name
													)}`}
												>
													{activity_type_category_name} :{' '}
													<Text
														as='span'
														fontWeight='500'
														className={`activity-type-${toSnakeCase(
															activity_type_name
														)}`}
													>
														{activity_type_name}
													</Text>
												</Text>
												{!!completion && (
													<HStack
														spacing={2}
														flex={1}
														className={`activity-completion-container`}
													>
														<Slider
															className={`activity-completion-slider`}
															aria-label='slider-ex-2'
															isDisabled={true}
															// defaultValue={completion || 0}
															value={completion}
															w='150px'
														>
															<SliderTrack
																height={2}
																borderRadius='full'
																className={`activity-completion-track`}
															>
																<SliderFilledTrack
																	bg='orange.600'
																	className={`activity-completion-fill`}
																/>
															</SliderTrack>
														</Slider>
														<Text
															className={`activity-completion-text-percentage`}
															color={localStorage.getItem('color')}
														>
															{`${completion}%` || 0}
														</Text>
													</HStack>
												)}
											</HStack>
										</VStack>
										<VStack
											className={`activity-creation-details`}
											w='300px'
											justifyContent='flex-start'
											alignItems='flex-start'
										>
											<Text fontSize='12px' className={`activity-creator-text`}>
												{locale['Creator']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-creator-text-${toSnakeCase(
														operating_asset_first_name
													)}`}
												>
													{operating_asset_first_name}
												</Text>
											</Text>
										</VStack>
										<VStack
											alignItems='flex-start'
											className={`activity-time-log-container`}
										>
											<Text
												fontSize='12px'
												display='flex'
												alignItems='center'
												className={`activity-time-text-${activity_datetime_start_expected}`}
											>
												<Icon
													as={MdDateRange}
													w={4}
													h={4}
													className={`activity-date-time-icon`}
												/>
												&nbsp; {locale['Start Date']}: &nbsp;
												{/* <Text display='flex' alignItems='center' > */}
												{!!activity_datetime_start_expected
													? format(
															new Date(
																activity_datetime_start_expected
																	.split(' ')
																	.join('T')
															),
															'dd MMM yyyy'
													  )
													: !!activity_datetime_start_expected
													? format(
															new Date(
																activity_datetime_start_expected
																	.split(' ')
																	.join('T')
															),
															'dd MMM yyy'
													  )
													: null}
												{/* </Text> */}
											</Text>
											{!!activity_datetime_end_deferred && (
												<Text
													fontSize='12px'
													display='flex'
													alignItems='center'
													color={timeColor(activity_datetime_end_deferred)}
													className={`activity-end-time-text-${activity_datetime_end_deferred}`}
												>
													<Icon
														as={MdTimelapse}
														w={4}
														h={4}
														className={`activity-time-laps-icon`}
													/>
													&nbsp; {locale['Due Date']} : &nbsp;
													{/* <Text display='flex' alignItems='center'  color={timeColor(activity_datetime_end_deferred)}> */}
													{activity_datetime_end_deferred.includes('date') ||
													activity_datetime_end_deferred.includes('0000-00-00')
														? activity_datetime_end_deferred
														: format(
																new Date(
																	activity_datetime_end_deferred
																		.split(' ')
																		.join('T')
																),
																'dd MMM yyyy'
														  )}
													{/* </Text> */}
												</Text>
											)}
											{!!activity_datetime_end_status &&
												organization_ai_bot_enabled === 1 && (
													<Text
														fontSize='12px'
														display='flex'
														alignItems='center'
														color={timeColor(activity_datetime_end_status)}
														className={`activity-time-text-${activity_datetime_end_deferred}`}
													>
														<Icon
															as={MdAccessTime}
															w={4}
															h={4}
															className={`activity-date-time-icon`}
														/>
														&nbsp; {locale['Status Due Date']} : &nbsp;
														{/* <Text display='flex' alignItems='center' color={timeColor(activity_datetime_end_status)} > */}
														{!!activity_datetime_end_status
															? format(
																	new Date(
																		activity_datetime_end_status
																			.split(' ')
																			.join('T')
																	),
																	'dd MMM yyyy'
															  )
															: !!activity_datetime_end_status
															? format(
																	new Date(
																		activity_datetime_end_status
																			.split(' ')
																			.join('T')
																	),
																	'dd MMM yyy'
															  )
															: null}
														{/* </Text> */}
													</Text>
												)}
										</VStack>
										{activity_type_category_id === 66 && (
											<div style={{ padding: '3px 3px' }}>
												<QRCodeSVG
													renderAs='canvas'
													style={{
														height: '75px',
														width: '75px',
													}}
													level='Q'
													value={`${activity_cuid_1}`}
												/>
											</div>
										)}
									</HStack>
									<HStack
										className={`activity-cuid-container`}
										justifyContent='space-between'
										mt={2}
										alignItems='center'
										spacing={4}
									>
										{activity_cuid_1 && (
											<Text
												mb={1}
												fontSize='12px'
												className={`activity-CUID-1-text`}
											>
												{locale['CUID 1']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-CUID-1-text-${activity_cuid_1}`}
												>
													{activity_cuid_1}
												</Text>{' '}
												<Icon
													className={`activity-CUID-1-copy-icon`}
													as={FiCopy}
													cursor='pointer'
													w={3}
													h={3}
												/>{' '}
											</Text>
										)}
										{activity_cuid_2 && (
											<Text
												mb={1}
												fontSize='12px'
												className={`activity-CUID-2-text`}
											>
												{locale['CUID 2']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-CUID-2-text-${activity_cuid_2}`}
												>
													{activity_cuid_2}
												</Text>{' '}
												<Icon
													className={`activity-CUID-2-copy-icon`}
													as={FiCopy}
													cursor='pointer'
													w={3}
													h={3}
												/>{' '}
											</Text>
										)}
										{activity_cuid_3 && (
											<Text
												mb={1}
												className={`activity-CUID-3-text`}
												fontSize='12px'
											>
												{locale['CUID 3']} :{' '}
												<Text
													as='span'
													fontWeight='500'
													className={`activity-CUID-3-text-${activity_cuid_3}`}
												>
													{activity_cuid_3}
												</Text>{' '}
											</Text>
										)}
									</HStack>
								</Box>
							</Collapse>
							{!!exportPayload &&
								!!exportPayload.f_list &&
								exportPayload.f_list.length > 0 &&
								exportPayload.f_list.map(urlData => {
									urlData.form_transaction_id = urlData.form_tx_id
									urlData.organization_id = exportPayload.organization_id
									urlData.account_id = exportPayload.account_id
									urlData.workforce_id = exportPayload.workforce_id
									urlData.asset_id = exportPayload.asset_id
									urlData.auth_asset_id = exportPayload.asset_id
									urlData.asset_type_id = 0
									urlData.asset_token_auth = exportPayload.asset_token_auth
									urlData.activity_id = exportPayload.activity_id
									urlData.activity_type_id = exportPayload.activity_type_id
									urlData.device_os_id = 5
									urlData.activity_type_category_id =
										exportPayload.activity_type_category_id
									urlData.asset_unread_updates_count = 0
									urlData.organization_flag_enable_grid = 0
									urlData.asset_image_path = ''
									return (
										<VStack
											className={`team-collaborations-timeline-container`}
											w='100%'
											h='full'
											// style={{
											// 	transformOrigin: 'bottom',
											// 	transition: 'transform 0.26s ease',
											// }}
											// minH={'calc(100vh - 370px)'}
											// flex={1}
										>
											<Box h='100%' w='100%' px={1}>
												<SmartFormContextProvider>
													{urlData && (
														<FormEditPanel
															isOpen={true}
															onClose={() => {}}
															activity={{ activity_id: urlData.activity_id }}
															formData={urlData}
															refillForm={() => {}}
															isFormEnable={false}
															height='100vh'
															hideedit={true}
														/>
													)}
												</SmartFormContextProvider>
											</Box>
										</VStack>
									)
								})}
						</Card>
					</page>
				)}
			</div>
		)
	}
)

export default ExportData
